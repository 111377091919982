import React from 'react'
import { GrClose } from 'react-icons/gr';
import './PopUp.css'

function PopUp(props) {
  return (props.trigger) ? (
    <div className='popup'>
      <div className='popup-inner'>
        <div className='close-btn' onClick={() => props.setTrigger(false)}> <GrClose/></div>
            { props.children}
      </div>

    </div>
  ) : "";
}

export default PopUp
