import React from 'react'
import ImageGallery from 'react-image-gallery';
import '../style/Cars.css'
import car1 from '../assets/Cars/car-1.jpg'
import car2 from '../assets/Cars/car-2.jpg'
import car3 from '../assets/Cars/car-3.jpg'
import car4 from '../assets/Cars/car-4.jpg'
import car5 from '../assets/Cars/car-5.jpg'
import car6 from '../assets/Cars/car-6.jpg'
import car7 from '../assets/Cars/car-7.jpg'
import car8 from '../assets/Cars/car-8.jpg'


const images = [
    {
      original: car1,
      thumbnail: car1,
    },
    {
        original: car2,
        thumbnail: car2,
    },
    {
        original: car3,
        thumbnail: car3,
    },
    {
        original: car4,
        thumbnail: car4,
    },
    {
      original: car5,
      thumbnail: car5,
    },
    {
    original: car6,
    thumbnail: car6,
    },
    {
    original: car7,
    thumbnail: car7,
    },
    {
    original: car8,
    thumbnail: car8,
    },
  ];
function Cars() {
  return (
    <div className='Cars'>
        <h1 className='Cars-title'> ايجار سيارات</h1>
            <p className='Cars-description'> 
                استأجر سيارتك وإستلمها في اسطنبول
                 ريزا , طرابزون او في سابانجا 
                <br/>
                سيارات متعددة واسعار مختلفة, ابعث صورة رخصتك و خلي الباقى علينا
            </p>
        <div className='Cars-gallery'>
            <div className='Cars-img'>
                <ImageGallery items={images} />
            </div>
        </div>
    </div>
  )
}

export default Cars
