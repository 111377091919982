import React, {useState} from "react";
import "./Accordion.css"
import {AiOutlineClose} from "react-icons/ai"
import {IoIosArrowDown} from "react-icons/io"

const dataCollection = [
    {
        title: "أكواخ",
        text: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    },
    {
        title: "ايجار سيارات",
        text: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    },
    {
        title: "شقق",
        text: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    },
    {
        title: "رحلات سياحية",
        text: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    },
];

function Accordion () {
    const [accordion, setAccordion] = useState(-1);

    function toggleAccordion(index) {
        if (index === accordion) {
            setAccordion(-1)
            return
        }
        setAccordion(index);
    }

    return (
        <div className="Accordion">
            <div className="accordion__faq">
                {dataCollection. map((item, index) =>
                    <div key={index}>
                        <div className="accordion__faq-heading">
                            <h3 className={accordion === index ? "active" : ""}> {item.title} </h3>
                            <div onClick={() => toggleAccordion(index)}>
                            {accordion === index ? (
                            <> <span className="verticle"> <AiOutlineClose/> </span> </>
                            ) : (
                            <> <span className="verticle"> <IoIosArrowDown/> </span> </>
                            )}
                        </div>
                        </div>
                        
                        <div className="Accordion-text">
                            <p className={accordion === index ? "active" : "inactive"}>{item.text}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
        )}
export default Accordion
