import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Header from "./component/Header/Header";
import Footer from "./component/Footer/Footer";
import Whatsapp from "./component/Whatsapp/Whatsapp"
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Page404 from './pages/Page404';
import Cabins from './pages/Cabins';
import Cabin from './pages/Cabin';
import Apartments from './pages/Apartments';
import Apartment from './pages/Apartment';
import Cars from './pages/Cars';
import Activites from './pages/Activites';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( < React.StrictMode >
    <BrowserRouter >
        <Header/>
        <main>
        <Routes >
            <Route path = "/" exact element={ <Home/> }/> 
            <Route path = "/AboutUs" element={ <AboutUs/> }/> 
            <Route path = "/Cabins" element={ <Cabins/> }/>
            <Route path = "/Cabin/:cabinId" element={ <Cabin/> }/>
            <Route path = "/Apartments" element={ <Apartments/> }/>
            <Route path = "/Apartment/:apartmentId" element={ <Apartment/> }/>
            <Route path = "/Cars" element={ <Cars/> }/>
            <Route path = "/Activites" element={ <Activites/> }/>
            <Route path = "*" element={ <Page404/> }/>  
        </Routes > 
        </main>
        <Whatsapp/>
    </BrowserRouter> 
        <Footer/>
    </React.StrictMode >
);
