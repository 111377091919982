import React from 'react'
import {NavLink} from 'react-router-dom';
import './Header.css';
import logo from "../../assets/Logo.png"
import { HiMenu } from 'react-icons/hi';


function Header() {
  const closeMenu = () => {
    document.getElementById('check').checked = false;
  }
  return (
    <nav className='nav-bar'>
        <input type="checkbox" id='check' className="menu-btn"/>
            <label htmlFor="check" className='checkbtn' >
                <HiMenu/>
            </label>
        <a href='/'><img src={logo} alt="logo Yuras" className='header-img'/></a>
        <ul className='nav-ul'>
            <li className='nav-item'> <NavLink to="/" className="nav-link" onClick={closeMenu}>الرئيسية </NavLink></li>
            <li className='nav-item'> <NavLink to="/AboutUs" className="nav-link" onClick={closeMenu}>من نحن</NavLink></li>
            <li className='nav-item'> <NavLink to="/Activites" className="nav-link" onClick={closeMenu}>رحلات سياحية</NavLink></li>
            <li className='nav-item'> <NavLink to="/Cabins" className="nav-link" onClick={closeMenu}>أكواخ</NavLink></li>
            <li className='nav-item'> <NavLink to="/Apartments" className="nav-link" onClick={closeMenu}>شقق</NavLink></li>
            <li className='nav-item'> <NavLink to="/Cars" className="nav-link"onClick={closeMenu} >سيارات </NavLink></li>
        </ul>
    </nav>
  )
}

export default Header
