import React from "react"
import "./Gallery.css"


function Gallery(props) {
    return (
        <div className="gallery">
            <img src={props.cover} alt={props.title}/>  
            <div className="filtreGallery"></div>
            <div className="GalleryTitle">
                <h2 className="gallery-title">{props.title}</h2>
                <h2 className="gallery-price">{props.price} ابتداء من </h2>
            </div>
        </div> 
    )
}

export default Gallery