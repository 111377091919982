import { useState } from "react"
import "./Carroussel.css"
import { GrPrevious } from "react-icons/gr";
import { GrNext } from "react-icons/gr";


function Carroussel({ pictures }) {
    const [index, setNewImg] = useState(0)  
    
    function btnsuivant() {
        
        var i = index;
        i++                     
        if (i >= pictures.length) i = 0
        setNewImg(i)
    }
    function btnprecedent() {
        var i = index;
        i--
        if (i < 0) i = pictures.length - 1
        setNewImg(i)
    }

    function navigation() {
        return (
            <div className="navigation">
                <div className="precedent" onClick={btnprecedent}> <GrPrevious/> </div>
                <div className="suivant" onClick={btnsuivant}> <GrNext/> </div>
                <p>{index + 1}/{pictures.length}</p>
            </div>
        )
    }
    return (
        <div className="photo" style={{backgroundImage: `url("${pictures[index]}")`,}}>
        {pictures.length > 1 ? navigation() : ""}
        </div>
    )
}

export default Carroussel



