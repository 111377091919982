import React from 'react'
import ImageGallery from 'react-image-gallery';
import '../style/Activites.css'
import activity1 from '../assets/Activites/activity-1.jpg'
import activity2 from '../assets/Activites/activity-2.jpg'
import activity3 from '../assets/Activites/activity-3.jpg'
import activity4 from '../assets/Activites/activity-4.jpg'
import activity5 from '../assets/Activites/activity-5.jpg'


const images = [
    {
      original: activity1,
      thumbnail: activity1,
    },
    {
        original: activity2,
        thumbnail: activity2,
    },
    {
        original: activity3,
        thumbnail: activity3,
    },
    {
        original: activity4,
        thumbnail: activity4,
    },
    {
        original: activity5,
        thumbnail: activity5,
    },
  ];
function Activites() {
  return (
    <div className='Activites'>
        <h1 className='Activites-title'> رحلات سياحية</h1>
        <h2 className='Activites-h2'>كل رحلاتنا يومية، تشمل : المواصلات، المرشد السياحي،  الغداء والنقل البحري</h2>
         <div className='Activities-box'> 
          <div className='activity-card'>
            <h3 className='Activites-h3'>رحلة جزر الأميرات</h3>
            <p className='Activites-description'> 
            ‏الانطلاق من إسطنبول وركوب العبارة البحرية <br/> 
            ‏زيارة جزيرة بيوك أدا اكبر جزيرة من مجموعة جزر الأميرات <br/>
            ‏زيارة جزيرة هيبلي ثاني أكبر جزيرة بين مجموعة جزر الأميرات <br/>
            وقت حر في الجزيرة للإستمتاع بالتسوق وركوب الدراجات الهوائية <br/>
            ‏وجبة الغداء : بوفيه مفتوح في السفينة <br/>
            </p>
          </div>
          <div className='activity-card'>
          <h3 className='Activites-h3'>رحلة السبت يلوا </h3>
            <p className='Activites-description'>
              ‏الانطلاق من إسطنبول مرورا من جسر البوسفور <br/>
              ‏ركوب العبارة البحرية من محطة قيبزي إسطنبول إلى محطة يلوا <br/>
              التوقف عند محطة ميتورات السفاري في يلوا <br/>
              زيارة قرية تيرمال ذات الطبيعة الخلابة <br/>
              زيارة الينابيع العلاجية الحارة في حمامات ترمال في يلوا <br/>
              زيارة شلال صودوشان القريب من المنطقة في يلوا 
            </p>
        </div>
        </div>
        <div className='Activities-box'>
          <div className='activity-card'>
          <h3 className='Activites-h3'>‏رحلة صبنجة و المعشوقية</h3>
            <p className='Activites-description'> 
            ‏الانطلاق من إسطنبول مرورا من جسر البوسفور<br/> 
            زيارة التراس الزجاجي ، ‏ذو الإطلالة الرائعة<br/>
            ‏زيارة بحيرة صبنجة وهي اكبر بحيرة مياه عذبه في تركيا <br/>
    ‏زيارة قرية وشلالات المعشوقية والاستمتاع بجمال المنطقة <br/>
    ‏جولة السفاري في المعشوقية بالدباب <br/>
    ‏ركوب الحبل في المعشوقية <br/>
    ‏زيارة مناحل العسل والحلقوم التركي في صبنجة
            </p>
            
          </div>
          <div className='activity-card'>
            <h3 className='Activites-h3'>رحلة بورصه، جبل اولوداغ </h3>
            <p className='Activites-description'> 
                      ‏الانطلاق من إسطنبول مرورا من جسر البوسفور <br/>
              ‏ ‏ركوب العبارة البحرية من محطة إسطنبول إلى محطة يلوا <br/>
              التوقف عند محطة ميتورات السفاري في يلوا <br/>
              زيارة معرض الحلقوم التركي <br/>
              ‏ ‏زيارة مكان التلفريك الكبيرة المغلق من مدينة بورصة إلى جبل اولوداغ <br/>
              التوقف عند محطة ميتورات الجتسكي الثلج <br/>
              ‏زيارة مكان المسابقة التي تقام عليه مسابقات التزلج الأولمبية العالمية <br/>
              ‏وجبة الغداء : مشاوي في مطعم ذو إطلالة رائعة على جبل اولوداغ <br/>
              ‏زيارة الشجرة المعمرة التاريخية والتي يبلغ عمرها 612 عاما <br/>
              ‏زيارة مناحل العسل والمربيات في جبال مدينة بورصة وغاباتها <br/>
              زيارة مول بول مارك للتسوق
            </p>
          </div>
        </div>
        <div className='Activites-gallery'>
            <div className='Activites-img'>
                <ImageGallery items={images} />
            </div>
        </div>
    </div>
  )
}

export default Activites
