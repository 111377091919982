import React from 'react'
import '../style/AboutUs.css'
import ReactWhatsapp from "react-whatsapp";
import { TbClick } from 'react-icons/tb';

function AboutUs() {
  return (
    <div className='AboutUs'>
      <h1 className='AboutUs-title'> احجز من يوراس وسافر مرتاح الرأس </h1>
      <p className='AboutUs-section1'>
        يوراس لحجز كافة رحلاتكم السياحية : نوفر لكم أكواخ, فيلات, شقق و حجز هوتيلات مع توفير خدمة التوصيل او ايجار السيارات <br/>
         لتتمتع بالتنقل بكامل رفاهية وارياحية خلال رحلتك بأسعار منافسه<br/>
        كما نوفر تذاكر و رحلات سياحية في جميع أرجاء تركيا <br/>
        كل ما يلزمك لقضاء عطلة ممتعة و مريحة مع ضمان اسعار مميزة وعروض حصرية
      </p>
      <div className='AboutUs-section'>
        <p className='AboutUs-section2'>
          كل ما عليك فعله هو إرسال طلباتك و سوف نوفر لك رحلة مخصصة لا تنسى 
        </p>
        <div className="AboutUs-reserve">
          <ReactWhatsapp number="+218911441400">
            <button className="AboutUs-reservebtn"> <TbClick/> احجز الأن  </button>  
            </ReactWhatsapp>   
        </div>
      </div>
      <div className='location'>
        <p className='location-title'>
          ابرز المناطق لقضاء عطلة لا مثيل لها بكل راحة واطمئنان  
        </p>
        <div className='location-trio'>
          <div className='city-location'>
            <h2 className='city-location-title'> SAPANCA </h2>
            <p className='city-location-p'>
              وهي من أكبر البحيرات الطبيعية في العالم وتقع في قرية سابانجا (صبنجة)
              التي تتبع لولاية سكاريا وهي من الأماكن المفضلة لجميع السياح في تركيا <br/> ويطل عليها جبل كارتبه الذي يوفر اطلالة ساحرة على مدينة سابانجا وبحيرتها <br/> لا سيما وانها افضل الاماكن السياحية في تركيا في الشتاء كونها وجهة مثالية في موسم الثلوج لمحبي التزلج والالعاب الشتوية كما تتميز بقربها على اسطنبول
            </p> 
          </div>
          <div className='city-location'>
            <h2 className='city-location-title'>TRABZON</h2>
            <p className='city-location-p'>
                      تقع طرابزون في الجهة الشمالية الشرقية من تركيا بين جبال زينغانا على ساحل البحر الأسود<br/>
              ومن أهم المناطق القريبة من طرابزون قرية  Uzungöl و قرية ايدر اللتان يعتبران من أروع بقاع السياحة في تركيا جنة الأرض ومن المناطق السياحية الطبيعية الشهيرة<br/> التي تضمها طرابزون مرتفعات
              السلطان مراد و بحيرة سيرا جول ، كما تضم العديد من المعالم التاريخية مثل قصر أتاتورك و دير سوميلا الذي يعود تاريخه لعام 386 من الميلاد.

            </p>
          </div>
          <div className='city-location'> 
            <h2 className='city-location-title'>RİZE</h2>
            <p className='city-location-p'> 
              تعتبر مدينة ريزا احدى اجمل مدن تركيا من خلال التنوع السياحي التي تتمتع به. تقع مدينة ريزا شمال تركيا في الجهة الشرقية مطلّةً على ساحل البحر الأسود.
              <br/> و هي من الوجهات السياحة المهمة في تركيا ، حيث تتميز بطبيعتها الساحرة ومناظرها الطبيعية التي تأسر القلوب وتخطف الأبصار
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs