import React, { useState } from "react"
import "../style/Cabin.css"
import AllLogement from '../data/apartments.json'
import { useParams } from "react-router-dom"
import PopUp from "../component/PopUp/PopUp"
import Page404 from "./Page404"
import { ImLocation } from 'react-icons/im';
import ReactWhatsapp from "react-whatsapp";
import { TbClick } from 'react-icons/tb';
import { BsArrowsFullscreen } from 'react-icons/bs';
import Carroussel from "../component/Carroussel/Carroussel"



function Apartment() {
    
    const {apartmentId} = useParams()
    const Logement = AllLogement.find((item) => item.id == apartmentId)
    console.log(Logement)
    const [buttonPopUp, setButtonPopUp] = useState(false);

    
    return Logement === undefined ? (
        <Page404 />
        ) : (
            <div className="LogementContainer">
                <div className="LogementWrapper">

                    {/* Section 1  */}

                    <div className="Logement-Banner">
                                <div  className="Imagecover"> 
                                    <img src={Logement.cover} className="Imgcover" />
                                </div>
                                <div className="AffichePlus" >
                                <button className="AffichePlusBtn" onClick={() => setButtonPopUp(true)} > <BsArrowsFullscreen /> عرض المزيد من الصور </button>
                                <PopUp trigger={buttonPopUp} setTrigger= {setButtonPopUp}>
                                    <div>
                                        <Carroussel pictures={Logement.pictures}/>
                                    </div>
                                </PopUp>
                                </div>
                        
                    </div>

                    {/* Section 2 */}

                    <div className="titre">
                        <h2>{Logement.title}</h2>
                    </div>

                    {/* Section 3 */}

                    <div className="price-location">
                        <p className="Details"> <ImLocation />{Logement.location} </p>
                        <h3 className="Details"> <span className="Price">{Logement.price} </span> ابتداء من</h3>   
                    </div>

                    {/* Section 4 */}

                    <div className="reserve">
                        <ReactWhatsapp number="+218911441400"> 
                            <button className="reservebtn"> <TbClick/> احجز الأن  </button>  
                        </ReactWhatsapp>   
                    </div>

                    {/* Section 5 */}

                    <div className="descript">
                        <div className="descript-section">
                            <h3 className="descript-title">  </h3>
                            <div className="Logement-Description">
                                {Logement.description}
                            </div>
                        </div>
                        <div className="descript-section">
                            <h3 className="descript-title">  </h3>
                            <ul className="Logement-Equipment">
                                {Logement.equipments.map((equipments) => {
                                    return <li key={equipments}>{equipments}</li>
                                })}
                            </ul>  
                        </div>        
                    </div>


                </div>
            </div>
        )
}

export default Apartment