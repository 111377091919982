import React from "react";
import AllApartments from '../data/apartments.json';
import { Link } from "react-router-dom"
import Gallery from "../component/Gallery/Gallery"

function Apartments() {
    return ( 
    <div className = "Apartments" >
        <div className="GalleryLogements">
                {AllApartments.map((apartment) => (
                    <Link to={`/Apartment/${apartment.id}`} key={apartment.id}>
                    <Gallery
                        cover={apartment.cover}
                        title={apartment.title}
                        price={apartment.price}
                        id={apartment.id}
                    />
                    </Link>
                ))}
            </div>
    </div>
       )   
    }

export default Apartments