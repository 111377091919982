import './ImageSlider.css';
import PhotosSlider from '../../data/data';
import { useEffect, useState } from "react"

function ImageSlider  () {
   
    const [currentState,setCurrentState]= useState(0)
    useEffect(()=>{
        const timer = setTimeout(() =>{
            if(currentState===2){
                setCurrentState(0)
            }else{
                setCurrentState(currentState+1)
            }
        }, 5000)
        return ()=> clearTimeout(timer)
   },[currentState])
    const ImageSlide ={
        backgroundImage: `url(${PhotosSlider[currentState].url})`,
    }
    const goToNext = (currentState) =>{
      setCurrentState(currentState)  
    }
    return ( 
        <div className="Slider">   
            <div className='ImageSlider' style={ImageSlide}> </div>
                <div className='description'>
                    <div className='SliderText'>
                    <h1>Yuras</h1>
                    <p>احجـز مـن يـوراس وسافـر مرتـاح الـراس</p>
                    </div>
                    <div className='carousel-boullt'>
                        {
                            PhotosSlider.map((PhotosSlider,currentState)=>(
                            <span key={currentState} onClick={()=> goToNext(currentState)}></span>
                            ))
                        }
                    </div>
                </div>
        </div>
    )
}

export default ImageSlider;