import React from "react";
import "../style/Home.css"
import ImageSlider from '../component/ImageSlider/ImageSlider'
import BestSellerImg from '../assets/2305-2.jpg'
import Accordion from "../component/Accordion/Accordion"



function Home() {
    return ( 
    <div className = "Home" >

      <div className="Sliderimg">
        <ImageSlider/>
      </div>

      <div className="Service-container">

        <div className="Service-categories">
          <div className="category">
            <Accordion/>
          </div>
        </div>

        <div className="Service-text">
          <h2 className="service-titles"> خدماتنا </h2>
          <p className="service-p"> الخدمات التي نوفرها <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed hendrerit metus, eget dapibus tellus. Aliquam at maximus lorem. Fusce ultrices metus massa. </p>
        </div>

      </div>

      <div className="BestSeller-container">
        <div className="best-text">
          <h2 className="best-title"> الأكثر طلب </h2>
          <h3 className="best-logment-title">Nidar Bungalow</h3>
          <p className="best-p">   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed hendrerit metus, eget dapibus tellus. Aliquam at maximus lorem. <br/> <a href="/Cabin/2305" className="best-link"> للحجز و معرفة التفاصيل </a> </p>
        </div>

        <div className="BestSeller-logement">
              <div className="BestSeller-img">
              <a href="/Cabin/2305"><img src={BestSellerImg} alt="cabin image" className="bestimg" /></a> 
              </div>
        </div>
      </div>

    </div>
       )   
    }

export default Home