import React from "react"
import './Whatsapp.css';
import ReactWhatsapp from "react-whatsapp";
import WhatsappLogo from "../../assets/whatsapp-logo.png"



function Whatsapp() {
    return ( 
        <div className="Whatsapp">   
       <ReactWhatsapp number="+218911441400"> 
        <img src={WhatsappLogo} alt="WhatsApp contact" className="Whatsapp-logo"/> 
       </ReactWhatsapp>
                   
        </div>
    )
}

export default Whatsapp;