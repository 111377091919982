import React from "react"
import logoFooter from "../../assets/Logo.png"
import "./Footer.css"
import ReactWhatsapp from "react-whatsapp";
import { BsWhatsapp } from 'react-icons/bs';
import { BsFacebook } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';



function Footer () {
    return (
        <footer>
            <div className="Footer">
                <div>
                    <a href="/" className="LogoFooter">
                        <img src={logoFooter} alt="logo Yuras" />
                    </a>
                </div>
                <div className="social">
                    <div className="IF-social">
                        <ReactWhatsapp number="+218911441400"> 
                            <p className="socila-media"> <BsWhatsapp /> +218 91 144 14 00 </p>
                        </ReactWhatsapp>
                        <a href="https://www.facebook.com/profile.php?id=100083294083369" className="socila-media" target="_blank"> <BsFacebook /> Facebook</a>
                        <a  href="https://www.instagram.com/yuras.lytr/?igshid=YmMyMTA2M2Y%3D" className="socila-media" target="_blank"> <BsInstagram /> Instagram</a>  
                    </div>
                    <div className="design">
                        Designed by <a href="https://www.instagram.com/wissa.dev/" className="design-a" target="_blank"> Wissa Dev</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer