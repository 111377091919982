import cabin1 from "../assets/cabin1.jpg"
import cabin2 from "../assets/cabin2.jpg"
import cabin3 from "../assets/cabin3.jpg"
const PhotosSlider =[
    { url: cabin1,
    title: 'photo',
    body: 'welcome'
},
    { url: cabin2,
    title: 'photo2',
    body: 'welcome2'},
    { url: cabin3,
    title: 'photo3',
    body: 'welcome3'},
]
export default PhotosSlider