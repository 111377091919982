import React from "react";
import "../style/Cabins.css"
import AllCabins from '../data/cabins.json';
import { Link } from "react-router-dom"
import Gallery from "../component/Gallery/Gallery"


function Cabins() {
    return ( 
    <div className = "Cabins" >
        <div className="GalleryLogements">
                {AllCabins.map((cabin) => (
                    <Link to={`/Cabin/${cabin.id}`} key={cabin.id}>
                    <Gallery
                        cover={cabin.cover}
                        title={cabin.title}
                        price={cabin.price}
                        id={cabin.id}
                    />
                    </Link>
                ))}
            </div>
    </div>
       )   
    }

export default Cabins